import React from "react";
import resume from "../resources/resume.pdf"
import { Link } from "react-router-dom";
import TimelineSelector from "../components/resume-page/timeline-selector";

function ResumePage (props) {
    return (
        <div className="resume-page" style={{marginTop:"20px"}}>
            <Link to={resume} target="_blank" rel="noopener noreferrer">
                <button type="button" className="view-pdf-button">
                    View as PDF
                </button>
            </Link>
            {/* Contact Info */}
            <div className="resume-section-container" style={{marginTop:"20px"}}>
                <h4 className="resume-subheader">Contact Me</h4>
                {!props.isMobile ? (
                <div style={{display: "grid", gridTemplateColumns:"1fr 1fr", gridGap:"20px", width:"100%"}}>
                    <div>
                        <p className="resume-list no-margins">By phone: (612) 356-4309</p>
                        <p className="resume-list no-margins">By email: paul.calderon1733@gmail.com</p>
                    </div>
                </div>
                ) : (
                <div>
                    <p className="resume-list no-margins">(612) 356-4309</p>
                    <p className="resume-list no-margins">paul.calderon1733@gmail.com</p>
                    <p className="resume-list no-margins">https://www.linkedin.com/in/paul-calderon/</p>
                </div>
                )}
            </div>
            {/* Opening */}
            <div className="resume-section-container">
                <h4 className="resume-subheader" style={{textAlign:"center", fontSize:"24px"}}>STRATEGY – INNOVATION – DESIGN – PLANNING – CONSTRUCTION</h4>
                <p className="resume-list">Highly accomplished and innovative executive with extensive design and construction experience in multi-unit Fortune 500 corporations and consulting agencies.  A strategic and results driven leader with a successful track record of developing and championing transformational ideas that deliver enterprise-wide initiatives within complex matrix organizational structures, and leading large cross-functional teams.  Excels at leading and inspiring strategic problem solving and developing customer-centric solutions that proactively respond to the dynamics of the marketplace to deliver bottom-line improvement.</p>
            </div>
            {/* Skills */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Skills</h4>
                {!props.isMobile ? (
                    <div dangerouslySetInnerHTML={{ __html: createTable() }} />
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: createMobileTable() }} />
                )}
            </div>
            {/* Employment Experience */}
            <TimelineSelector/>
            {/* Education */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Education</h4>
                <div className="resume-section-text">
                    <ul className="resume-list">
                        <li>Master of Architecture, University of Michigan – Ann Arbor, MI</li>
                        <li>Bachelor of Science in Architecture, The Ohio State University – Columbus, OH</li>
                    </ul>
                </div>
            </div>
            {/* Professional Certification, Special Skills, and Other */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Professional Certification, Special Skills, and Other</h4>
                <div className="resume-section-text">
                    <ul className="resume-list">
                        <li>Registered Architect – State of Michigan</li>
                        <li>NCARB Certified – National Council of Architectural Registration Boards (licensing reciprocity in other states)</li>
                        <li>Member design:retail editorial board</li>
                        <li>Member ICSC – International Council of Shopping Centers</li>
                        <li>Member AIA – American Institute of Architects</li>
                        <li>Fluent in Spanish</li>
                    </ul>
                </div>
            </div>
            {/* Honors */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Honors</h4>
                <div className="resume-section-text">
                    <ul className="resume-list">
                        <li>2002 NASFM Honor Award – Marshall Field’s State Street Cosmetics Department Reinvention</li>
                        <li>2001 NASFM Honor Award – Marshall Field’s State Street Bridal Department Reinvention</li>
                        <li>1987 AIA Honor Award for Excellence in Masonry Design – Springfield Township, OH Fire Station No 3</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

let skills = ["Strategic Leadership", "Strategic Planning", "Real Estate Development", "Architecture",
    "Reinvention", "Capital Planning & Prioritization", "Construction Management", "Interior Design",
    "Brand Development", "Store Design & Planning", "Contract Negotiation", "Global Sourcing",
    "Omni-Channel", "Development Process Optimization", "Contract Management", "Value-Engineering",
    "Business Planning", "Project Management Software", "Financial Feasibility", "Sustainability",
    "Process Improvement", "Facilities Management", "Team Building", "Staff Development"];

function createTable() {
    let table = "<table class='resume-table'><tbody>";
    for( let i = 0; i < skills.length; i+=4) {
        table = table + 
        "<tr>" +
        `<td>${skills[i]}</td>` +
        `<td>${skills[i+1]}</td>` +
        `<td>${skills[i+2]}</td>` +
        `<td>${skills[i+3]}</td>` +
        "</tr>"
    }
    table = table + "</tbody></table>"
    return table;
}

function createMobileTable() {
    let table = "<table class='resume-table'><tbody>";
    for( let i = 0; i < skills.length; i+=2) {
        table = table + 
        "<tr>" +
        `<td>${skills[i]}</td>` +
        `<td>${skills[i+1]}</td>` +
        "</tr>"
    }
    table = table + "</tbody></table>"
    return table;
}

export default ResumePage;