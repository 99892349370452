import React, { useState } from "react";
import BurlingtonLogo from '../../resources/burlington-logo.png';
import KohlsLogo from '../../resources/kohls-logo.png';
import TargetLogo from '../../resources/target-logo.png';
import JGALogo from '../../resources/jga-logo.png';

function TimelineSelector(props) {
  const burlingtonContent =
  "<li>Built over 230 New Stores and 110 Remodels, increased Meets Brand Standard stores from 32% to 68% in 4 years</li>" +
  "<li>Direct internal reports include a multi-disciplined team with 3 Vice Presidents and 72 associates</li>" +
  "<li>Manage outsource partners:  6 Arch/Eng consultants, 18 General Contractors, and 6 Fixture Installation Contractors</li>" +
  "<li>Responsibilities include:  Capital Plan, Prototypes, New Builds, Conversions, Renovations, Remodels, and Retrofits</li>" +
  "<li>Role includes: Branding, Innovation, Design, Store Planning, Architecture, In-Store Marketing, Fixture Design,Purchasing, and Construction</li>" +
  "<br/><strong>2016-2020</strong> - SVP Store Innovation, Design and Construction";

  const kohlsContent =
  "<li>Planned and built over 750 New Stores, 500 Remodels, and 14 corporate/commercial properties</li>" +
  "<li>Direct internal reports included a multi-disciplined team with 8 Vice Presidents and 250 associates</li>" +
  "<li>Managed outsource partners - 8 Arch/Eng consultants, 40 General Contractors, and 6 Facilities Contractors</li>" +
  "<li>Responsibilities included:  Capital Plan, Expense Plan, Prototypes, New Builds, Conversions, Remodels, and Retrofits</li>" +
  "<li>Role included: Branding, Innovation, Store Design, Store Planning, Architecture, Sustainability, Site Development,Construction, Fixture Design, Purchasing, Workplace/Office Planning, and Facilities Management</li>" +
  "<br/><strong>2010-2016</strong> - SVP Store Environment and Development" +
  "<br/><strong>2009-2010</strong> - SVP Store Design, Planning, Innovation, Architecture and Sustainability" +
  "<br/><strong>2005-2009</strong> - VP Store Design, Planning, Innovation and Architecture" +
  "<br/><strong>2004-2005</strong> - Director Store Design, Planning and Architecture";

  const targetContent =
  "<li>Direct internal reports included 45 associates located in two corporate offices (MN &amp; CA)</li>" +
  "<li>Role included: Branding, Innovation, Store Design, Store Planning, Architecture, Food Service Design, Fixture Design,and Workplace/Office Planning</li>" +
  "<br/><strong>1999-2004</strong> - Director Marshall Field’s, Mervyn’s, and Target Store Innovation" +
  "<br/><strong>1997-1999</strong> - Director Mervyn’s Store Design and Planning" +
  "<br/><strong>1995-1997</strong> - Director Mervyn’s Prototype and Target Food Service Design" +
  "<br/><strong>1994-1995</strong> - Manager Mervyn’s Prototype Planning" +
  "<br/><strong>1992-1994</strong> - Design Lead, Target Prototype Design and Planning";

  const jgaContent =
  "<li>Role included:  Branding Strategy, Innovation, Design, Store Planning, Architecture, and Account Management</li>";
  
  const [selectedItem, setSelectedItem] = useState("burlington");
  const [titleContent, setTitleContent] = useState("SVP Store Innovation, Design and Construction - Burlington Corporation");
  const [bodyContent, setBodyContent] = useState(burlingtonContent);

  function setDisplayedItem(selectedItem) {
    setSelectedItem(selectedItem);
    if(selectedItem === "burlington") {
      setTitleContent("SVP Store Innovation, Design and Construction - Burlington Corporation");
      setBodyContent(burlingtonContent);
    } else if(selectedItem === "kohls") {
      setTitleContent("SVP Store Environment and Development - Kohl’s Corporation");
      setBodyContent(kohlsContent);
    } else if(selectedItem === "target") {
      setTitleContent("Director Marshall Field’s, Mervyn’s, and Target Store Innovation - Target Corporation");
      setBodyContent(targetContent);
    } else if(selectedItem === "jga") {
      setTitleContent("Architectural Project Manager and Design Lead - JGA Branding & Retail Strategy Consultants");
      setBodyContent(jgaContent);
    }
    else {
      setTitleContent("Select a company to view more details.");
      setBodyContent("");
    }
  }

  return (
    <div className="timeline-section resume-section-container">
      <h4 className="resume-subheader">Employment Experience</h4>
      {/* COMPANY SELECT BUTTONS */}
        <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"20px"}}>
            <div className={selectedItem === "burlington" ? "company-select-selected company-select" : "company-select-not-selected company-select"} style={{backgroundImage:`url(${BurlingtonLogo})`, width:(selectedItem === "burlington" ? "23%" : "20%"), paddingTop:(selectedItem === "burlington" ? "23%" : "20%")}}  onClick={() => setDisplayedItem("burlington")}></div>
            <div className={selectedItem === "kohls" ? "company-select-selected company-select" : "company-select-not-selected company-select"} style={{backgroundImage:`url(${KohlsLogo})`, width:(selectedItem === "kohls" ? "23%" : "20%"), paddingTop:(selectedItem === "kohls" ? "23%" : "20%")}}  onClick={() => setDisplayedItem("kohls")}></div>
            <div className={selectedItem === "target" ? "company-select-selected company-select" : "company-select-not-selected company-select"} style={{backgroundImage:`url(${TargetLogo})`, width:(selectedItem === "target" ? "23%" : "20%"), paddingTop:(selectedItem === "target" ? "23%" : "20%")}}  onClick={() => setDisplayedItem("target")}></div>
            <div className={selectedItem === "jga" ? "company-select-selected company-select" : "company-select-not-selected company-select"} style={{backgroundImage:`url(${JGALogo})`, width:(selectedItem === "jga" ? "23%" : "20%"), paddingTop:(selectedItem === "jga" ? "23%" : "20%")}}  onClick={() => setDisplayedItem("jga")}></div>
        </div>
        {/* CONTENT */}
        <div className="resume-section-text">
          <h6 className="resume-subsubheader">{titleContent}</h6>
          <ul className="resume-list" dangerouslySetInnerHTML={{ __html: bodyContent }}></ul>
        </div>
    </div>
  );
}

export default TimelineSelector;