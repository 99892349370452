import React from "react";

function PageTitle (props) {
    return (
        <div className="page-title-div">
            {!props.isMobile ? (
                <div style={{marginLeft:"20%"}}><h2 style={{margin:"10px"}}>{props.pageTitle}</h2></div>
            ) : (
                <div style={{marginLeft:"5%"}}><h2 style={{margin:"10px"}}>{props.pageTitle}</h2></div>
            )}
        </div>
    )
}

export default PageTitle;