import React from "react";
import achievements from "../resources/achievements.pdf";
import { Link } from 'react-router-dom';


function AchievementsPage () {
    return (
        <div className="resume-page"style={{marginTop:"20px"}}>
            <Link to={achievements} target="_blank" rel="noopener noreferrer">
                <button type="button" className="view-pdf-button">
                    View as PDF
                </button>
            </Link>
            {/* Leadership and Innovation */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Leadership &amp; Innovation</h4>
                <div className="resume-section-text">
                    <ul className='resume-list'>
                        <li className="achievements-item"><strong>Identified strategic need and created Design Principles</strong> for Burlington that define the store environment brand.  Developed and delivered next generation store which grew customer base by 5%, secured access to better real estate locations, and delivered next generation prototype at same cost as previous prototype (cost neutral)</li>
                        <li className="achievements-item"><strong>Determined need and established leadership role in accelerating the development and implementation of smaller footprint stores</strong> at Burlington.  Reduced prototype store size by 37%, increased sales productivity/SF by 163%, fueled new store growth by increasing pipeline/quantity of new stores by 20%, or approximately $800 Mil in sales.</li>
                        <li className="achievements-item"><strong>Created growth opportunities via new store formats and chaired test/learn/rollout development process for innovation and emerging technologies</strong> which expedited learning and increased hit rate by 30%.  Big wins included:  buy on-line/pick-up in-store, machine-learning cameras, self and assisted checkout.  R&amp;D included: pop-up stores, apparel specialty stores, home stores, and rural market co-development.</li>
                        <li className="achievements-item"><strong>Identified need to create a long-range organizational plan to support rapidly expanding program, recruit and shape a high performing property development team.</strong>  Implemented a lean organization at Burlington that delivered program which grew by 3.8X yet team only grew by 2X, increased associate engagement score from 71% in FY16 to 88% in FY19, edited external partners based on KPI’s to fewer but larger relationships improving performance by 20%.</li>
                    </ul>
                </div>
            </div>
            {/* General Retail */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">General Retail</h4>
                <div className="resume-section-text">
                    <ul className='resume-list'>
                        <li className="achievements-item"><strong>Improved and streamlined processes to accelerate profitable growth</strong> which enabled growing the new store program within 3 years by 145% at Burlington and by 40% at Kohl’s, reduced construction cost by 5% and timeline by 8%.</li>
                        <li className="achievements-item"><strong>Delivered outstanding development results</strong> and successfully delivered Kohl’s program that reached $980 Mil/year that included 117 new stores and 100 remodels which were primarily owned properties.  Successfully delivered Burlington program that reached 76 new stores and 39 remodels per year which were primarily leased properties, and successful delivery was heavily dependent on ability to negotiate and influence without authority.</li>
                        <li className="achievements-item"><strong>Identified efficiencies and reinvented processes to improve profitability of remodel program</strong> at Burlington.  Reduced comp sales erosion during remodel by approximately 300 basis points, increased comp sales post-remodel by approximately 500 basis points, and increased quantity of sales weeks within fiscal year by 25%.</li>
                        <li className="achievements-item"><strong>Improved real estate strategy at Burlington based on customer profile and site intel</strong> which increased quantity of seed points.  Increased pipeline by over 150% resulting in majority of new stores meeting or exceeding pro-forma.</li>
                        <li className="achievements-item"><strong>Implemented a strategic global sourcing initiative</strong> to reduce fixture cost which delivered annual savings of $4 Mil or 8% at Burlington, and $5 Mil or 5% at Kohl’s.</li>
                        <li className="achievements-item"><strong>Identified need and implemented a Lean &amp; Agile project development process</strong> which increased project manager accountability via stand-up scrum meetings, reduced false starts and lost work-effort, and accelerated communication/coordination of program decisions and CAPEX/OPEX approvals.</li>
                        <li className="achievements-item"><strong>Provided thought leadership and innovation consulting related to branding and market positioning</strong> for multiple retailers and corporations.  Developed new store format for Target – major learning was that Target could sell better private label and exclusive brands at higher price points which was rolled out to the entire company, and was the prelude to many successful brand collaborations and co-branding.</li>
                        <li className="achievements-item"><strong>Designed and implemented Marshall Field’s reinvention</strong> of 1 Mil SF flagship store in Chicago.  Implemented $55 Mil plan within an 12-month window, added over 80 new shop-in-shops and new food offerings, increased foot traffic on upper floors by over15%, reinvented and expanded key departments including:  26K SF cosmetics department, 14K SF women’s designer department/28-shop, 8K SF bridal salon, 10K SF beauty salon, 63K SF restaurant floor/7th on State, 26K SF women’s shoe department, 120K SF home/furniture department.</li>
                        <li className="achievements-item"><strong>Implemented Prototype Committee process</strong> that enabled executive leadership team to pro-actively review/approve all changes and avoid any surprises in a time-effective and efficient manner, expedited development of 3 generations of protypes at Kohl’s and 5 generations of prototypes at Marshall Field’s/Mervyn’s/Target.</li>
                    </ul>
                </div>
            </div>
            {/* Beauty and Vendor Brand Shop-In-Shop */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Beauty &amp; Vendor Brand Shop-in-Shop</h4>
                <div className="resume-section-text">
                    <ul className='resume-list'>
                        <li className="achievements-item"><strong>Reinvented and rolled out new Beauty department</strong> at Kohl’s aimed at competing with Sephora.  Built credibility within the beauty industry with an elevated in-store experience, secured over 25 new and better national brands, increased sales productivity/SF by 30%, rolled out shop to 400 stores with the plan to rollout to 1,200 stores.</li>
                        <li className="achievements-item"><strong>Designed and implemented over 200 different shop-in-shop brand collaborations</strong> for new brand launches and reinventions of existing brands, negotiated win/win store environment solutions for vendor and retail company.  Rolled out  national and exclusive brand shop-in-shops based on positive financial performance, including:  Clinique, Shiseido, Origins, Bobby Brown, Louis Vuitton, Gucci, Coach, Polo, Tommy Hilfiger, Vera by Vera Wang, Juicy Couture, Nike, Under Armour, Adidas, Levi’s, Chaps, Carter’s, Dyson, Beats, Calphalon, Food Network.</li>
                    </ul>
                </div>
            </div>
            {/* Grocery and Restaurant */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Grocery &amp; Restaurant</h4>
                <div className="resume-section-text">
                    <ul className='resume-list'>
                        <li className="achievements-item"><strong>Designed and planned first Super Target prototype</strong> which added grocery to existing general merchandise offering.  First Super Target in Omaha was the springboard for Target entering the grocery business, and later rolling grocery to all stores in order to increase foot traffic via increased frequency of store visits/month and acquiring new customers by providing a one-stop shop.</li>
                        <li className="achievements-item"><strong>Developed and built multiple food service concepts</strong> within retail stores and corporate headquarters.  Rolled out Starbuck’s, tested Pizza Hut/Taco Bell in Target and Caribou Coffee in Kohl’s which focused on maximizing sales productivity per SF at front of store.  Implemented 25K restaurant/food service and catering for 40K event center within Kohl’s corporate headquarters focused on providing a better/best food offering in order to be viewed by associates as an employer of choice and retain associates within the building to increase productivity.</li>
                    </ul>
                </div>
            </div>
            {/* Workplace, Corporate, and Facilities Management */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Workplace, Corporate, &amp; Facilities Management</h4>
                <div className="resume-section-text">
                    <ul className='resume-list'>
                        <li className="achievements-item"><strong>Developed and executed a global sustainability strategy</strong> at Kohl’s with LEED certification for new stores at gold level, base LEED certification for existing stores, and creation of a CSR-corporate social responsibility report.  Achieved thought leadership status by delivering one of the largest sustainability programs in the retail, reduced average energy cost in new stores by 25%.</li>
                        <li className="achievements-item"><strong>Adopted and implemented LEED sustainability principles</strong> at Burlington which reduced energy consumption in stores by 30% or over $14 Mil/year.</li>
                        <li className="achievements-item"><strong>Developed and implemented corporate campus strategy</strong> at Kohl’s.  Led business analysis that supported evaluating move of entire corporate campus from suburban Milwaukee to an urban setting in downtown business district and disposition of existing 1 Mil SF corporate office building.  Business analysis resulted in keeping corporate campus in its current location, purchased and converted adjacent 280K SF industrial building into a state of the art innovation center, increased overall building area to 369K SF by adding second floor within building shell, built assembly space for 1,200 with catering, created a kit of work/meeting spaces that supported traditional and virtual work environments.</li>
                        <li className="achievements-item"><strong>Optimized total cost of ownership based on life-cycle cost</strong> by maximizing synergies between design, construction, and facilities management.  Achieved an 8% savings in repair/replacement cost that was applied to 800 Kohl’s stores.</li>
                        <li className="achievements-item"><strong>Planned and constructed commercial properties</strong> to support Kohl’s on -going business growth and reduce optimize cost.  Built 3 new distribution/e-fulfillment centers with a total of 3 Mil SF, and 2 call centers to support growth in e-commerce with a total of 500K SF.</li>
                        <li className="achievements-item"><strong>Designed and constructed workplace/corporate office properties</strong> that supported Kohl’s need to have corporate office buildings were there is subject matter talent and expertise that would not relocate to Milwaukee.  Built 100K SF New York Design office and fashion event center in mid-town Manhattan for product development/merchandising teams, leveraged office environment for recruiting/retention and elevated perception of Kohl’s within the vendor community.  Built 30K SF Los Angeles Design office for west coast brands which achieved the same objectives as NY Design office.  Built 52K SF Innovation and Technology center for the IT team in Silicon Valley which enabled them to recruit/retain best-in-class talent, facilitating easy collaboration with other Silicon Valley based companies.</li>
                    </ul>
                </div>
            </div>
            {/* Life Sciences */}
            <div className="resume-section-container">
                <h4 className="resume-subheader">Life Sciences</h4>
                <div className="resume-section-text">
                    <ul className='resume-list'>
                        <li className="achievements-item"><strong>Designed and built patient-centric healthcare facilities</strong> that supported expanded services and elevated customer/patient experience.  Successfully delivered Children’s Hospital of Michigan Ambulatory Care Facility in Detroit, MI, and Foundation Park Alzheimer’s Care Center in Toledo, OH with an elevated environment, reduced cost by 12% and construction schedule by 20%.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AchievementsPage;