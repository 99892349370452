import React from "react";

function AboutPage (props) {
    return (
        <div className="about-page">
            {!props.isMobile ? (
            <img src={require("../resources/headshot.jpg")} className="project-card-image" alt='' style={{width:"15%", marginRight:"20px"}}></img>
            ) : (
            <img src={require("../resources/headshot.jpg")} className="project-card-image" alt='' style={{width:"45%", marginRight:"20px"}}></img>
            )}
            <p className="about-page-body-text">J. Paul Calderon is an accomplished and innovative senior executive with extensive design and construction experience.  His background in brand strategy, design, and architecture inform shaping environments and customer experiences applying human centered design paired with business analysis.  Paul is fueled by his passion for strategy and creating emotional connections for customers with brands.  He considers himself a ‘forever student’ by embracing continuous learning, keeping pulse of the competitive landscape, and encouraging out of the box thinking.  He has an innate ability to connect the dots across industries and building types.</p>
            <p className="about-page-body-text">His vision and drive to develop customer experiences that meet strategic goals contributed to his most recent success at Burlington Stores where he elevated and re-positioned the store environment brand with customers, vendors, and landlords.  Meanwhile, he vastly improved the productivity of his team by implementing strategic project management methods, more efficient processes, and increasing associate engagement to the company’s top 10%.</p>
            <p className="about-page-body-text">Paul is entrepreneurial and collaborative, a tenet he lives by identifying incremental growth opportunities over and above core workload requested by leadership, and pro-actively partnering with other departments to deliver big ideas that add the greatest value.  He excels at developing people based on relationships built on trust and being approachable, leading and inspiring others to identify business opportunities by fostering an open environment of exploration, designing effective solutions to complex business problems, and implementing those solutions via effective management of execution.</p>
            <p className="about-page-body-text">His colleagues and mentors describe Paul as a transformational leader who is:
                <ul className="no-margins"><li>Strategic and innovative</li><li>Entrepreneurial and collaborative</li><li>Visionary and passionate</li><li>Analytical and creative</li><li>Adaptable and resourceful</li></ul></p>
            <p className="about-page-body-text">His role and responsibilities span the entire life cycle of large-scale and complex property development programs.  Paul is looking to apply his experience and broad skill set in a way that has the most significant impact on the next organization he works with, either focused on strategy and design, or construction and managing execution.</p>
            <hr style={{height:"2px", borderWidth:"0", color:"#33312a", backgroundColor:"#33312a"}}></hr>
            <p className="about-page-body-text">Contact Me:</p>
            <p className="about-page-body-text">Email: paul.calderon1733@gmail.com</p>
            <p className="about-page-body-text">Phone: (612) 356-4309</p>
        </div>
    )
}

export default AboutPage;