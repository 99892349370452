import React from "react";
import { Link } from 'react-router-dom';

function Navbar () {
    return (
        <div className="navbar">
            <Link to="./" className="navbar-link-div"><div className="navbar-home-link-text"><img src={require("../resources/building-icon.png")} className="navbar-icon" alt=""></img>J. Paul Calderon</div></Link>
            <div className="navbar-links">
                <Link to="./resume"className="navbar-link-div"><div className="navbar-link">Resume</div></Link>
                <Link to="./achievements"className="navbar-link-div"><div className="navbar-link">Achievements</div></Link>
                <Link to="./portfolio" className="navbar-link-div"><div className="navbar-link">Portfolio</div></Link>
                <Link to="./about" className="navbar-link-div"><div className="navbar-link">About Me</div></Link>
                <Link to="./" className="navbar-link-div"><div className="navbar-link">Home</div></Link>
            </div>
            <div className="outline" style={{float: "left"}}></div>
        </div>
    )
}

export default Navbar;