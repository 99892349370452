import React from 'react';
import { useEffect, useState } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ResumePage from './pages/resume-page';
import AboutPage from './pages/about-page';
import ProjectsPage from './pages/projects-page';
import Navbar from './components/navbar';
import Footer from './components/footer';
import MobileNavbar from './components/mobile-navbar';
import AchievementsPage from './pages/achievements-page';
import PageTitle from './components/page-title';
import HomePage from './pages/home-page';

function App() {

  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 1250;

  return (
    <Router>
      {isMobile ? (
      <div className="App">
        <MobileNavbar />
        <Switch>
          <Route path="/portfolio" component={() => (<PageTitle pageTitle="Portfolio" isMobile={isMobile}/>)} />
          <Route path="/about" component={() => (<PageTitle pageTitle="About Me" isMobile={isMobile}/>)} />
          <Route path="/resume" component={() => (<PageTitle pageTitle="Resume" isMobile={isMobile}/>)} />
          <Route path="/achievements" component={() => (<PageTitle pageTitle="Selected Achievements" isMobile={isMobile}/>)} />
          <Route exact path="/" component={() => (<PageTitle pageTitle="J. Paul Calderon" isMobile={isMobile}/>)} />
        </Switch>
        <div className="mobile-container">
          <Switch>
            <Route path="/portfolio" component={() => (<ProjectsPage isMobile={isMobile}/>)} />
            <Route path="/about" component={() => (<AboutPage isMobile={isMobile}/>)} />
            <Route path="/resume" component={() => (<ResumePage isMobile={isMobile}/>)} />
            <Route path="/achievements" component={() => (<AchievementsPage />)} />
            <Route exact path="/" component={() => (<HomePage isMobile={isMobile} />)} />
          </Switch>
        </div>
        <Footer className="footer"/>
      </div>
      ) : (
        <div className="App">
        <Navbar />
        <Switch>
          <Route path="/portfolio" component={() => (<PageTitle pageTitle="Portfolio" isMobile={isMobile}/>)} />
          <Route path="/about" component={() => (<PageTitle pageTitle="About Me" isMobile={isMobile}/>)} />
          <Route path="/resume" component={() => (<PageTitle pageTitle="Resume" isMobile={isMobile}/>)} />
          <Route path="/achievements" component={() => (<PageTitle pageTitle="Selected Achievements" isMobile={isMobile}/>)} />
          <Route exact path="/" component={() => (<PageTitle pageTitle="J. Paul Calderon" isMobile={isMobile}/>)} />
        </Switch>
        <div className="normal-container">
          <Switch>
            <Route path="/portfolio" component={() => (<ProjectsPage isMobile={isMobile}/>)} />
            <Route path="/about" component={() => (<AboutPage isMobile={isMobile}/>)} />
            <Route path="/resume" component={() => (<ResumePage isMobile={isMobile}/>)} />
            <Route path="/achievements" component={() => (<AchievementsPage />)} />
            <Route exact path="/" component={() => (<HomePage isMobile={isMobile} />)} />
          </Switch>
        </div>
        <Footer className="footer"/>
      </div>
      )}
    </Router>
  );
}

export default App;
