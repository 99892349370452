import React from "react";

function Footer () {
    return (
        <div className="footer-container">
            <div className="outline"></div>
            <div className="footer-text-container">
                <a href="https://www.linkedin.com/in/paul-calderon/" className="no-margins" target="_blank" rel="noopener noreferrer"><div><img src={require("../resources/linkedin-icon.png")} className="footer-icon" alt=""></img></div></a>
                <label className="no-margins" style={{fontSize: "16px"}}>&#169; J. Paul Calderon 2020</label><br/>
                <label className="no-margins" style={{fontSize: "12px"}}>Website by Connor Calderon</label>
            </div>
        </div>
    )
}

export default Footer;