import React from "react";
import { Link } from "react-router-dom";

function HomePage (props) {
    return (
        <div className="home-page">
            <br/>
            {!props.isMobile ? (
            <div className="home-page-grid" style={{display:"grid", gridTemplateColumns:"50% 50%", gridGap:"10px"}}>
                <div style={{margin:"20px"}}>
                    <img src={require("../resources/about-icon.png")} style={{height:"72px"}} alt=""></img>
                    <h4 className="no-margins">About Me</h4>
                    <p style={{marginTop:"10px"}}>Read a short biography about me and the work I have done.</p>
                    <Link to="about" className="home-page-button-link"><div className="home-page-button-div">About Me</div></Link>
                </div>
                <div style={{margin:"20px"}}>
                    <img src={require("../resources/portfolio-icon.png")} style={{height:"72px"}} alt=""></img>
                    <h4 className="no-margins">Portfolio</h4>
                    <p style={{marginTop:"10px"}}>View some projects I have worked on over the course of my career.</p>
                    <Link to="portfolio" className="home-page-button-link"><div className="home-page-button-div">View My Portfolio</div></Link>
                </div>
                <div style={{margin:"20px"}}>
                    <img src={require("../resources/achievement-icon.png")} style={{height:"72px"}} alt=""></img>
                    <h4 className="no-margins">Achievements</h4>
                    <p style={{marginTop:"10px"}}>View some of my achievenements I have accomplished during my career.</p>
                    <Link to="achievements" className="home-page-button-link"><div className="home-page-button-div">View My Achievements</div></Link>
                </div>
                <div style={{margin:"20px"}}>
                    <img src={require("../resources/resume-icon.png")} style={{height:"72px"}} alt=""></img>
                    <h4 className="no-margins">Resume</h4>
                    <p style={{marginTop:"10px"}}>View my resume with descriptions of all of my past roles.</p>
                    <Link to="resume" className="home-page-button-link"><div className="home-page-button-div">View My Resume</div></Link>
                </div>
            </div>  
            ) : (
            <div className="home-page-grid" style={{display:"grid", gridTemplateColumns:"100%", gridGap:"10px"}}>
                <div style={{margin:"20px"}}>
                    <img src={require("../resources/about-icon.png")} style={{height:"72px"}} alt=""></img>
                    <h4 className="no-margins">About Me</h4>
                    <p style={{marginTop:"10px"}}>Read a short biography about me and the work I have done.</p>
                    <Link to="about" className="home-page-button-link"><div className="home-page-button-div">About Me</div></Link>
                </div>
                <div style={{margin:"20px"}}>
                    <img src={require("../resources/portfolio-icon.png")} style={{height:"72px"}} alt=""></img>
                    <h4 className="no-margins">Portfolio</h4>
                    <p style={{marginTop:"10px"}}>View some projects I have worked on over the course of my career.</p>
                    <Link to="portfolio" className="home-page-button-link"><div className="home-page-button-div">View My Portfolio</div></Link>
                </div>
                <div style={{margin:"20px"}}>
                    <img src={require("../resources/achievement-icon.png")} style={{height:"72px"}} alt=""></img>
                    <h4 className="no-margins">Achievements</h4>
                    <p style={{marginTop:"10px"}}>View some of my achievenements I have accomplished during my career.</p>
                    <Link to="achievements" className="home-page-button-link"><div className="home-page-button-div">View My Achievements</div></Link>
                </div>
                <div style={{margin:"20px"}}>
                    <img src={require("../resources/resume-icon.png")} style={{height:"72px"}} alt=""></img>
                    <h4 className="no-margins">Resume</h4>
                    <p style={{marginTop:"10px"}}>View my resume with descriptions of all of my past roles.</p>
                    <Link to="resume" className="home-page-button-link"><div className="home-page-button-div">View My Resume</div></Link>
                </div>
            </div> 
            )}
        </div>
    )
}

export default HomePage;