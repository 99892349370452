import React, { useState, useRef } from "react";
import Chevron from "./Chevron";

function ProjectCard(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion-icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion-icon" : "accordion-icon rotate"
    );
  }

  return (
    <div className="accordion-section">
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
            <p className="accordion-title">{props.title}</p>
            <Chevron className={`${setRotate}`} width={10} fill={"#2b2a2a"} />
        </button>
        <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion-content">  
            <img src={require(`../../resources/${props.imageName}`)} className="project-card-image" alt=''></img>
            <div className="accordion-text" dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
    </div>
  );
}

export default ProjectCard;