import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

function MobileNavbar() {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }
    return (
        <div className="navbar">
            <div className="accordion-section" style={{backgroundColor:"#33312a"}}>
                <button className={`accordion ${setActive}`} onClick={toggleAccordion} style={{backgroundColor:"#33312a"}}>
                    <div className="mobile-navbar-home-link-text"><img src={require("../resources/building-icon.png")} className="navbar-icon" alt=""></img>J. Paul Calderon</div>
                    <img src={require("../resources/hamburger-icon.png")} style={{height:"24px", marginLeft:"50%"}} alt=""></img>
                </button>
                <div ref={content} style={{ maxHeight: `${setHeight}`, marginLeft:"2%"}} className="mobile-accordion-content">
                    <div className="navbar-links">
                        <Link to="./" className="mobile-navbar-link-div" onClick={toggleAccordion}><div className="outline" style={{float: "left", height:"2px"}}></div><div className="mobile-navbar-link">Home</div></Link>
                        <Link to="./about" className="mobile-navbar-link-div" onClick={toggleAccordion}><div className="outline" style={{float: "left", height:"2px"}}></div><div className="mobile-navbar-link">About Me</div></Link>
                        <Link to="./portfolio" className="mobile-navbar-link-div" onClick={toggleAccordion}><div className="outline" style={{float: "left", height:"2px"}}></div><div className="mobile-navbar-link">Portfolio</div></Link>
                        <Link to="./achievements" className="mobile-navbar-link-div" onClick={toggleAccordion}><div className="outline" style={{float: "left", height:"2px"}}></div><div className="mobile-navbar-link">Achievements</div></Link>
                        <Link to="./resume" className="mobile-navbar-link-div" onClick={toggleAccordion}><div className="outline" style={{float: "left", height:"2px"}}></div><div className="mobile-navbar-link">Resume</div></Link>
                    </div>
                </div>
            </div>
            <div className="outline" style={{float: "left"}}></div>
        </div>
    )
}

export default MobileNavbar;