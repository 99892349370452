import React from "react";
import ProjectCard from "../components/projects-page/project-card";
import portfolio from '../resources/portfolio.pdf'
import { Link } from 'react-router-dom';


function ProjectsPage () {
    return (
        <div style={{marginTop:"20px"}}>
            <Link to={portfolio} target="_blank" rel="noopener noreferrer">
                <button type="button" className="view-pdf-button">
                    View as PDF
                </button>
            </Link>
            <div className="projects-page">
                <ProjectCard title="Right-Sizing Existing Stores" imageName="right-sizing-existing-stores.png" content=""/>
                <ProjectCard title="Omni-Channel" imageName="omni-channel.png" content=""/>
                <ProjectCard title="Small Footprint New Store Proto" imageName="small-footprint-new-store-proto.png" content=""/>
                <ProjectCard title="Next Gen Store Prototype" imageName="next-gen-store-prototype.png" content=""/>
                <ProjectCard title="Design Principles and Strategy" imageName="design-principles-and-strategy.png" content=""/>
                <ProjectCard title="Cosmetics Dept Reinvention" imageName="cosmetics-dept-reinvention.png" content=""/>
                <ProjectCard title="Pop-Up Store Concepts" imageName="pop-up-store-concepts.png" content=""/>
                <ProjectCard title="Jewelry Dept Reinvention" imageName="jewelry-dept-reinvention.png" content=""/>
                <ProjectCard title="Beauty Dept Reinvention" imageName="beauty-dept-reinvention.png" content=""/>
                <ProjectCard title="Innovation Center" imageName="innovation-center.png" content=""/>
                <ProjectCard title="Corporate HQ Expansion" imageName="corporate-hq-expansion.png" content=""/>
                <ProjectCard title="Inspiration" imageName="inspiration.png" content=""/>
            </div>
        </div>
    )
}

export default ProjectsPage;